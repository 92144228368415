import { IconButton } from "@mui/material";
import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { deleteDoc, doc } from "firebase/firestore";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const UserListItem = (props) => {
    const user = props.user;
    const role = props.role;
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };   

    const deleteAccount = async() => {
        const userRef = doc(db, 'users', user.uid);
        await deleteDoc(userRef).then(() => {
            navigate('/admin')
        })
    }
    return(
        <tr>
            <td>{user.email}</td>
            {user.create_account === true ? 
                <td>許可</td> :
                <td>未許可</td>
            }
            {user.edit_priceList === true ? 
                <td>許可</td> :
                <td>未許可</td>
            }
            {user.edit_calender === true ? 
                <td>許可</td> :
                <td>未許可</td>
            }
            <td>
                <IconButton onClick={role === "admin" ? () => navigate("/edit_account/" + user.id) : null}>
                    <EditIcon />
                </IconButton>
            </td>
            <td>
                <IconButton onClick={role === "admin" ? handleClickOpen : null}>
                    <DeleteIcon />
                </IconButton>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>{`${user?.email}のアカウント情報を削除します。`}</DialogTitle>
                    <DialogContent>
                        アカウントを削除すると、復元はできません。
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>キャンセル</Button>
                        <Button variant="contained" onClick={() => deleteAccount()}>削除</Button>
                    </DialogActions>
                </Dialog>
            </td>
        </tr>
    )
}
export default UserListItem;