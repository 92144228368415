import React, { useCallback, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Logo from "../img/linx_logo.png";

const SignIn = () => {
    const { signIn } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [passsword, setPassword] = useState("");

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, [setEmail]);
    const inputPassword = useCallback((e) => {
        setPassword(e.target.value);
    }, [setPassword]);

    const handleSubmit = async(e) => {
        try{
            await signIn(email, passsword);
            navigate('/');
        } catch(e) {
            window.alert('ログインに失敗しました', e)
        }
    }

    return(
        <div className="background">
            <div className="signin_wrapper">
                <img src={Logo} alt="LiNXロゴ" className="logo_image" />
                <div className="signin_form">
                    <div className="iput_item">
                        <p>LOGIN ID</p>
                        <input type="email" value={email} onChange={inputEmail} />
                    </div>
                    <div className="space_module_small" />
                    <div className="iput_item">
                        <p>PASSWORD</p>
                        <input type="password" value={passsword} onChange={inputPassword} />
                    </div>
                </div>
            </div>
            <div className="space_module_large" />
            <div className="text_button">
                <p onClick={() => navigate('/reset_password')}>Do you forget password?</p>
            </div>
            <div className="space_module_small" />
            <div className="login_button">
                <button onClick={() => handleSubmit()}>login</button>
            </div>
        </div>
    )
}

export default SignIn;