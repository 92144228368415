import React from "react";
import { useAuth } from "../context/AuthContext";
import Logo from "../img/linx_logo.png";

const CompletePage = () => {
    const { logout } = useAuth();
    return(
        <div className="background">
            <div className="complete_container">
                <h2>登録完了！</h2>
                <div className="space_module_small" />
                <p>
                    登録完了しました！<br />
                    一度ログアウトして、操作を再開してください。
                </p>
                <div className="space_module_small" />
                <div className="login_button">
                    <button onClick={() => logout()}>ログアウト</button>
                </div>
            </div>
            <div className="bottom_logo">
                <img src={Logo} alt="LiNXロゴ" />
            </div>
        </div>
    )
}
export default CompletePage;