import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import EditApartmentItem from "../component/EditApartmentItem";
import { useNavigate } from "react-router-dom";

const EditApartment = () => {
    const path = window.location.href.split('/edit_apartment/')[1];
    const id = path.split('/')[0];
    const navigate = useNavigate();
    const hierarchy = path.split('/')[1];
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        const apartmentRef = doc(db, 'apartment', id);
        getDoc(apartmentRef).then(snapshot => {
            const data = snapshot.data();
            setRooms(data.room)
        })
    }, []);
    const handleSubmit = async() => {
        const apartmentRef = doc(db, 'apartment', id);
        await updateDoc(apartmentRef, {
            room: rooms
        }).then(() => {
            navigate('/price_list/' + id)
        }).catch((e) => {
            alert('エラー:', e)
        })
    }
    return(
        <div className="edit_root">
            <h2>{hierarchy}階の編集</h2>
            <div className="space_module_midium" />
            <div className="edit_room">
                {rooms.map((room, index) => {
                    if(room.hierarchy === Number(hierarchy)) {
                        return(
                            <EditApartmentItem room={room} rooms={rooms} setRooms={setRooms} index={index}/>
                        )
                    } else {
                        <div className="no_visual" />
                    }
                })} 
            </div>
            <div className="space_module_midium" />
            <div className="edit_button">
                <button onClick={() => handleSubmit()}>編集</button>
            </div>
        </div>
    )
}
export default EditApartment;