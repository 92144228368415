import { Checkbox } from "@mui/material";
import React from "react";

const CheckBoxItem = (props) => {
    const handleChange = (e) => {
        props.setValue(e.target.checked)
    }
    return(
        <Checkbox 
            checked={props.checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    )
}
export default CheckBoxItem;