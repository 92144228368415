import React, { useEffect, useState } from "react";
import Logo from "../img/linx_logo.png";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

const Admin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setUser(data);
        })
    }, [])
    return(
        <div className="background">
            <div className="home_buttons">
                <div className="home_button">
                    <button onClick={() => navigate('/create_account')}>アカウント作成</button>
                </div>
                <div className="home_button">
                    <button onClick={() => navigate('/price_list/' + user?.apartment_id)}>価格表管理</button>
                </div>
                <div className="home_button">
                    <button onClick={() => navigate('/calendar/' + user?.apartment_id)}>予定表管理</button>
                </div>
                <div className="home_button">
                    <button onClick={() => navigate('/admin_list/' + user?.apartment_id)}>管理者一覧</button>
                </div>
                <div className="home_button">
                    <button onClick={() => navigate('/my_account')}>マイページ</button>
                </div>
            </div>
            <div className="space_module_small" />
            <div className="logout top">
                <button onClick={() => navigate('/')}>TOPへ</button>
            </div>
            <div className="bottom_logo">
                <img src={Logo} alt="LiNXロゴ" />
            </div>
        </div>
    )
}
export default Admin;