import { deleteDoc, doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MyAccount = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };     
    const { logout } = useAuth();

    useEffect(() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        getDoc(userRef).then((snapshot) => {
            const data = snapshot.data();
            setUser(data)
        })
    }, [])

    const deleteAccount = async() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        await deleteDoc(userRef).then(() => {
            user.delete()
        })
    }
    return(
        <div className="account_root">
            <h2>ユーザー情報</h2>
            {user && (
                <div className="account_view">
                    <div className="account_item">
                        <p className="account_field">メールアドレス</p>
                        <p className="account_data">{user.email}</p>
                    </div>
                    <div className="account_item">
                        <p className="account_field">アカウント作成管理</p>
                        {user.create_account === true ? 
                            <p className="account_data">許可</p> :
                            <p className="account_data">未許可</p>
                        }
                    </div>
                    <div className="account_item">
                        <p className="account_field">価格表管理</p>
                        {user.edit_priceList === true ? 
                            <p className="account_data">許可</p> :
                            <p className="account_data">未許可</p>
                        }
                    </div>
                    <div className="account_item">
                        <p className="account_field">予定表管理</p>
                        {user.edit_calender === true ? 
                            <p className="account_data">許可</p> :
                            <p className="account_data">未許可</p>
                        }
                    </div>
                </div>
            )}
            <div className="logout">
                <button onClick={() => navigate('/admin')}>戻る</button>
            </div>
            <div className="logout">
                <button onClick={() => logout()}>ログアウト</button>
            </div>
            <div className="logout del">
                <button onClick={handleClickOpen}>アカウント消去</button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>{`${user?.email}のアカウント情報を削除します。`}</DialogTitle>
                    <DialogContent>
                        アカウントを削除すると、復元はできません。
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>キャンセル</Button>
                        <Button variant="contained" onClick={() => deleteAccount()}>削除</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default MyAccount;