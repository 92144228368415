import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import CheckBoxItem from "../UIKit/CheckBoxItem";
import { useNavigate } from "react-router-dom";

const EditAccount = () => {
    const path = window.location.href;
    const id = path.split('/edit_account/')[1];
    const navigate = useNavigate();
    const [createAccount, setCreateAccount] = useState(false);
    const [editMonthlyCost, setEditMonthlyCost] = useState(false);
    const [editCalender, setEditCalender] = useState(false);
    const [editPriceList, setEditPriceList] = useState(false);

    useEffect(() => {
        const userRef = doc(db, 'users', id);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setCreateAccount(data.create_account);
            setEditMonthlyCost(data.edit_monthlyCost);
            setEditCalender(data.edit_calender);
            setEditPriceList(data.edit_priceList);
        })
    }, []);

    const handleSubmit = async() => {
        const userRef = doc(db, 'users', id);
        updateDoc(userRef, {
            create_account: createAccount,
            edit_monthlyCost: editMonthlyCost,
            edit_calender: editCalender,
            edit_priceList: editPriceList
        }).then(() => {
            navigate('/admin');
        })
    }
    
    return(
        <div className="background">
            <div className="check_container">
                <h2>アカウント管理権限</h2>
                <div className="space_module_small" />
                <div className="check_item">
                    <CheckBoxItem checked={createAccount} setValue={setCreateAccount} />
                    <p>アカウント作成権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editPriceList} setValue={setEditPriceList} />
                    <p>価格表編集権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editMonthlyCost} setValue={setEditMonthlyCost} />
                    <p>駐車場管理権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editCalender} setValue={setEditCalender} />
                    <p>カレンダー管理権限</p>
                </div>
            </div>
            <div className="space_module_midium" />
            <div className="login_button">
                <button onClick={() => handleSubmit()}>権限編集</button>
            </div>
        </div>
    )
}
export default EditAccount;