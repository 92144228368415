import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../img/linx_logo.png";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, [setEmail]);

    const handleSubmit = async(e) => {
        await sendPasswordResetEmail(auth, email).then(() => {
            navigate('/mail_sended')
        }).catch(() => {
            alert('ご入力いただいたメールアドレスは登録されておりません。')
        })
    }
    return(
        <div className="background">
            <div className="signin_wrapper">
                <img src={Logo} alt="LiNXロゴ" className="logo_image" />
                <div className="signin_form">
                    <div className="iput_item">
                        <p>LOGIN ID</p>
                        <input type="email" value={email} onChange={inputEmail} />
                    </div>
                </div>
            </div>
            <div className="space_module_large" />
            <div style={{color: "#fff"}}>
                <p>
                    登録しているアカウントのメールアドレスをご入力ください。<br />
                </p>
            </div>
            <div className="space_module_small" />
            <div className="login_button">
                <button onClick={() => handleSubmit()}>Send email</button>
            </div>
        </div>
    )
}

export default ResetPassword;