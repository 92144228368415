import React from "react";
import Logo from "../img/linx_logo.png";
import { useNavigate } from "react-router-dom";

const MailSended = () => {
    const navigate = useNavigate();
    return(
        <div className="background">
            <div className="complete_container">
                <h2>送信完了いたしました</h2>
                <div className="space_module_small" />
                <p>
                    ご入力いただいたメールアドレスにパスワードリセット用のリンクを送信しておりますので、<br />
                    そちらのリンクをクリックしてパスワードのリセットを行なってください。
                </p>
                <div className="space_module_small" />
                <div className="login_button">
                    <button onClick={() => navigate('/signin')}>ログイン画面へ</button>
                </div>
            </div>
            <div className="bottom_logo">
                <img src={Logo} alt="LiNXロゴ" />
            </div>
        </div>
    )
}
export default MailSended;