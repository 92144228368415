import { doc, getDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import CheckBoxItem from "../UIKit/CheckBoxItem";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const CreateAccount = () => {
    const { registAccount } = useAuth();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [createAccount, setCreateAccount] = useState(false);
    const [editMonthlyCost, setEditMonthlyCost] = useState(false);
    const [editCalender, setEditCalender] = useState(false);
    const [editPriceList, setEditPriceList] = useState(false);

    const inputEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, [setEmail]);
    const inputPassword = useCallback((e) => {
        setPassword(e.target.value);
    }, [setPassword]);

    useEffect(() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setUser(data);
        })
    }, []);

    const handleSubmit = async() => {
        try{
            await registAccount(email, password, user.apartment_id, user.apartment_url, createAccount, editCalender, editMonthlyCost, editPriceList);
            navigate('/complete')
        } catch(e) {
            window.alert('登録失敗')
        }
    }
    
    return(
        <div className="background">
            <div className="create_input">
                <input type="email" value={email} onChange={inputEmail} placeholder="メールアドレス" />
            </div>
            <div className="space_module_small" />
            <div className="create_input">
                <input type="password" value={password} onChange={inputPassword} placeholder="パスワード" />
            </div>
            <div className="space_module_midium" />
            <div className="check_container">
                <h2>アカウント管理権限</h2>
                <p>{user?.email}</p>
                <div className="space_module_small" />
                <div className="check_item">
                    <CheckBoxItem checked={createAccount} setValue={setCreateAccount} />
                    <p>アカウント作成権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editPriceList} setValue={setEditPriceList} />
                    <p>価格表編集権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editMonthlyCost} setValue={setEditMonthlyCost} />
                    <p>駐車場管理権限</p>
                </div>
                <div className="check_item">
                    <CheckBoxItem checked={editCalender} setValue={setEditCalender} />
                    <p>カレンダー管理権限</p>
                </div>
            </div>
            <div className="space_module_small" />
            <div className="login_button">
                <button onClick={() => handleSubmit()}>新規アカウント作成</button>
            </div>
        </div>
    )
}
export default CreateAccount;