import React from "react";
import { useNavigate } from "react-router-dom";

const PriceListItem = (props) => {
    const docs = props.docs;
    const hierarchy = props.index + 1;
    const id = props.id;
    const navigate = useNavigate();
    return(
        <div style={{display: "flex", alignItems: "center"}}>
            {docs.map((doc) => (
                <ul className="room_box">
                    <li className="item">{doc.num}</li>
                    {(() => {
                        if(doc.status === "vacancy") {
                            return(
                                <li>
                                    <div className="item type">{doc.type}</div>
                                    <div className="item">{doc.price}万円</div>
                                </li>
                            )
                        } else if(doc.status === "negotiation") {
                            return(
                                <li className="status neg">商談中</li>
                            )
                        } else if(doc.status === "contact") {
                            return(
                                <li className="status con">契約済</li>
                            )
                        } else if(doc.status === "application") {
                            return(
                                <li className="status app">申込済</li>
                            )
                        } else if(doc.status === "unpublished") {
                            return(
                                <li className="status unp">未公開</li>
                            )
                        } else if(doc.status === "nextSale") {
                            return(
                                <li className="status nex">次期分譲</li>
                            )
                        }
                    })()}
                </ul>
            ))}
            <div className="edit_apartment">
                <button onClick={() => navigate('/edit_apartment/' + `${id}/` + hierarchy)}>{hierarchy}階編集</button>
            </div>
        </div>
    )
}

export default PriceListItem;