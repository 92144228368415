export const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
// export const firebaseConfig = {
//     apiKey: "AIzaSyC9Ld2jIsfmN0ymqckPZVm_O5eGzNOv4XU",
//     authDomain: "linx-c8d5b.firebaseapp.com",
//     projectId: "linx-c8d5b",
//     storageBucket: "linx-c8d5b.appspot.com",
//     messagingSenderId: "853956436818",
//     appId: "1:853956436818:web:81458c97d82a009870908b",
//     measurementId: "G-HLEMHBQM86"
// };