import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({children}) {
    const [user, setUser] = useState("");
    const [loading, setLoading] = useState(true);

    function registAccount(email, password, apartmentId, apartmentUrl, createAccount, editCalender, editMonthlyCost, editPriceList) {
        return createUserWithEmailAndPassword(auth, email, password).then(result => {
            const user = result.user;
            if(user) {
                const uid = user.uid;
                const userDocumentRef = doc(db, 'users', uid);
                setDoc(userDocumentRef, {
                    email: email,
                    apartment_id: apartmentId,
                    apartment_url: apartmentUrl,
                    create_account: createAccount,
                    edit_calender: editCalender,
                    edit_monthlyCost: editMonthlyCost,
                    edit_priceList: editPriceList,
                    role: 'limited',
                    uid: uid,
                    created_at: serverTimestamp(),
                    updated_at: serverTimestamp(),
                }).catch((error) => {
                    alert('アカウント登録に失敗しました。');
                    throw new Error(error);
                })
            }
        })
    }
    function signIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password).then(result => {
            const userState = result.user;
            if(!userState) {
                alert('ユーザーIDを取得できません');
            }
            const uid = userState.uid;
            const userRef = doc(db, 'users', uid);
            return getDoc(userRef).then(snapshot => {
                const data = snapshot.data();
                if(!data) {
                    alert('ユーザーデータが存在しません。');
                    throw new Error('ユーザーデータが存在しません');
                }
            })
        })
    }

    function logout() {
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        }
    }, [])

    if(loading) {
        return<div className="loading">
            <CircularProgress />
            <p>Loading...</p>
        </div>
    }
    return <AuthContext.Provider value={{user, registAccount, signIn, logout}}>
        {children}
    </AuthContext.Provider>
}