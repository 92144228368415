import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import UserListItem from "../component/UserListItem";

const AdminList = () => {
    const path = window.location.href;
    const id = path.split('/admin_list/')[1];
    const [users, setUsers] = useState([]);
    const [role, setRole] = useState(null);
    useEffect(() => {
        const userRef = collection(db, 'users');
        const q = query(userRef, where('apartment_id', '==', id));
        getDocs(q).then((snapshots) => {
            setUsers(
                snapshots.docs.map((doc) => ({...doc.data(), id: doc.id}))
            )
        })
    }, []);

    useEffect(() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        getDoc(userRef).then((snapshot) => {
            const data = snapshot.data();
            setRole(data.role)
        })
    }, [])

    return(
        <div className="background admin">
            <table>
                <thead>
                    <tr>
                        <th>メールアドレス</th>
                        <th>アカウント作成</th>
                        <th>価格表管理</th>
                        <th>予定表管理</th>
                        <th>編集</th>
                        <th>消去</th>
                    </tr>
                </thead>
                <tbody>
                    {users.length > 0 && (
                        users.map((user) => <UserListItem key={user.id} user={user} role={role} />)
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default AdminList;