import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import PriceListItem from "../component/PriceListItem";

const PriceList = () => {
    const path = window.location.href;
    const id = path.split('/price_list/')[1];
    const [apartment, setApartment] = useState([]);

    useEffect(() => {
        const apartmentRef = doc(db, 'apartment', id);
        getDoc(apartmentRef).then(snapshot => {
            const data = snapshot.data();
            setApartment(data.room);
        })
    }, [])
    const dataByHierarchy = {};
    for (const data of apartment) {
        const hierarchy = data.hierarchy;
        if(!dataByHierarchy[hierarchy]) {
            dataByHierarchy[hierarchy] = [];
        }
        dataByHierarchy[hierarchy].push(data)
    }
    const dividedList = Object.values(dataByHierarchy);
    return(
        <div className="background price_list">
            {dividedList.map((docs, index) => <PriceListItem docs={docs} index={index} id={id} />)}
        </div>
    )
}
export default PriceList;