import React from "react";
import Logo from "../img/linx_logo.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    return(
        <div className="background">
            <div className="home_buttons">
                <div className="home_button">
                    <button onClick={() => navigate('/view_vr')}>VRツアーへ</button>
                </div>
                <div className="home_button">
                    <button onClick={() => navigate('/admin')}>管理画面へ</button>
                </div>
            </div>
            <div className="bottom_logo">
                <img src={Logo} alt="LiNXロゴ" />
            </div>
        </div>
    )
}
export default Home;