import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

const EditApartmentItem = (props) => {
    const room = props.room;
    const setRooms = props.setRooms;
    const rooms = props.rooms;
    const index = props.index
    
    const handleChange = (index, field, value) => {
        const updatedData = [...rooms];
        updatedData[index][field] = value;
        setRooms(updatedData);
    }

    return(
        <ul key={index} className="edit_box">
            <li className="edit_item">{room.num}</li>
            <li className="edit_item">
                <input value={room.price} onChange={(e) => handleChange(index, "price", e.target.value)} />万円
            </li>
            <li className="edit_item">
                <FormControl variant="standard" sx={{width: "100%" }}>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={room.status}
                    onChange={(e) => handleChange(index, "status", e.target.value)}
                    style={{width: "100%", height: 30}}
                >
                    <MenuItem value={"vacancy"}>公開</MenuItem>
                    <MenuItem value={"negotiation"}>商談中</MenuItem>
                    <MenuItem value={"contact"}>契約済</MenuItem>
                    <MenuItem value={"application"}>申込済</MenuItem>
                    <MenuItem value={"unpublished"}>未公開</MenuItem>
                    <MenuItem value={"nextSale"}>次期分譲</MenuItem>
                </Select>
                </FormControl>
            </li>
        </ul>
    )
}
export default EditApartmentItem;