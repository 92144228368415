import { addDoc, collection, deleteDoc, doc, onSnapshot, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { db } from "../firebase";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs";

const localizer = momentLocalizer(moment);
const formats = {
    dateFormat: 'D',
    dayFormat: 'D(ddd)',
    monthHeaderFormat: 'YYYY年M月',
    dayHeaderFormat: 'M月D日(ddd)',
    dayRangeHeaderFormat: 'YYYY年M月',
}

const CalendarPage = () => {
    const path = window.location.href.split('/calendar/')[1];
    const id = path.split('/')[0];
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [description, setDescription] = useState('');
    const [open, setOpen] = useState(false);
    const [eventId, setEventId] = useState('');
    const [openEvent, setOpenEvent] = useState(false);
    const [clickedEvent, setClickedEvent] = useState({});

    const handleClose = () => {
        setOpen(false);
    };
    const handleEventClose = () => {
        setOpenEvent(false);
    };

    const inputTitle = useCallback((e) => {
        setTitle(e.target.value)
    }, [setTitle])

    const inputDescription = useCallback((e) => {
        setDescription(e.target.value)
    }, [setDescription])
    
    const handleSlotSelected = (slotInfo) => {
        setStart(slotInfo.start);
        setEnd(slotInfo.end);
        setTitle('');
        setDescription('');
        setOpen(true);
    };
    
    const handleEventSelected = (event) => {
        setStart(event.start);
        setEnd(event.end);
        setTitle(event.title);
        setDescription(event.description);
        setClickedEvent(event);
        setEventId(event.id);
        setOpenEvent(true);
    };
    
    const handleStartTime = (date) => {
        setStart(date);
    };
    
    const handleEndTime = (date) => {
        setEnd(date);
    };
    
    const addEvent = async() => {
        const eventRef = collection(db, 'apartment', id, 'events');
        await addDoc(eventRef, {
            title: title,
            description: description,
            start: start.toDate(),
            end: end.toDate(),
            date: start.toDate(),
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
        }).then(() => {
            handleClose();
        })
    };
    
    const updateEvent = async() => {
        const eventRef = doc(db, 'apartment', id, 'events', eventId);
        await updateDoc(eventRef, {
            title: title,
            description: description === undefined ? "" : description,
            start: start.toDate(),
            end: end.toDate(),
            date: start.toDate(),
            created_at: serverTimestamp(),
            updated_at: serverTimestamp()
        }).then(() => {
            handleClose();
        })
        handleEventClose();
    };
    
    const deleteEvent = async() => {
        const eventRef = doc(db, 'apartment', id, 'events', eventId);
        await deleteDoc(eventRef).then(() => {
            handleEventClose();
        })
    };
  
    useEffect(() => {
        const eventRef = collection(db, 'apartment', id, 'events');
        onSnapshot(eventRef, snapshots => {
            const events = snapshots.docs.map((doc) => {
                const data = doc.data();
                return{
                    id: doc.id,
                    title: data.title,
                    description: data.description,
                    start: data.start.toDate(),
                    end: data.end.toDate(),
                }
            })
            setEvents(events)
        })
    }, []);

    return(
        <div className="calendar_root">
            <h2>予定表管理</h2>
            <div className="calendar">
                <Calendar 
                    localizer={localizer}
                    // views={["month", "week", "day"]}
                    views={["month"]}
                    view={"month"}
                    defaultDate={dayjs(new Date()).toDate()}
                    selectable={true}
                    onSelectEvent={event => handleEventSelected(event)}
                    onSelectSlot={slotInfo => handleSlotSelected(slotInfo)}
                    events={events}
                    timeslots={2}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: "100%", width: "100%"}}
                    formats={formats}
                />
                <Dialog
                    modal={false}
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>{`${moment(start).format('YYYY年MM月DD日')}イベント追加`}</DialogTitle>
                    <DialogContent>
                        <TextField
                            placeholder="イベントタイトル"
                            onChange={inputTitle}
                            value={title}
                        />
                        <br />
                        <TextField
                            placeholder="詳細"
                            value={description}
                            onChange={inputDescription}
                        />
                        <br />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                value={start}
                                onChange={handleStartTime}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <br />
                            <TimePicker
                                value={end}
                                onChange={handleEndTime}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>キャンセル</Button>
                        <Button onClick={() => addEvent()}>追加</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openEvent}
                    onClose={handleEventClose}
                >
                    <DialogTitle>{`${moment(start).format('YYYY年MM月DD日')}イベント確認・編集`}</DialogTitle>
                    <DialogContent>
                        <TextField
                            defaultValue={title}
                            placeholder="イベントタイトル"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <br />
                        <TextField
                            placeholder="詳細"
                            multiLine={true}
                            defaultValue={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <br />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                value={start}
                                onChange={handleStartTime}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <br />
                            <TimePicker
                                value={end}
                                onChange={handleEndTime}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleEventClose()}>キャンセル</Button>
                        <Button onClick={() => deleteEvent()}>削除</Button>
                        <Button onClick={() => updateEvent()}>編集</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div className="add_event">

            </div>
        </div>
    )
}

export default CalendarPage;