import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './Reset.css';
import AuthContextProvider from './context/AuthContext';
import SignIn from './template/SignIn';
import PrivateRoute from './PrivateRoute';
import Home from './template/Home';
import ViewVr from './template/ViewVr';
import Admin from './template/Admin';
import CreateAccount from './template/CreateAccount';
import CompletePage from './template/CompletePage';
import AdminList from './template/AdminList';
import PriceList from './template/PriceList';
import EditApartment from './template/EditApartment';
import CalendarPage from './template/Calendar';
import MyAccount from './template/MyAccount';
import EditAccount from './template/EditAccount';
import ResetPassword from './template/ReaetPassword';
import MailSended from './template/MailSended';

function App() {
  return (
    <AuthContextProvider>
      <HashRouter>
        <Routes>
          <Route path={'/signin'} element={<SignIn />} />
          <Route path={'/reset_password'} element={<ResetPassword />} />
          <Route path={'/mail_sended'} element={<MailSended />} />
          <Route 
            path={'/'}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/view_vr'}
            element={
              <PrivateRoute>
                <ViewVr />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/admin'}
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/create_account'}
            element={
              <PrivateRoute>
                <CreateAccount />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/complete'}
            element={
              <PrivateRoute>
                <CompletePage />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/admin_list/:id'}
            element={
              <PrivateRoute>
                <AdminList />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/price_list/:id'}
            element={
              <PrivateRoute>
                <PriceList />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/edit_apartment/:id/:id'}
            element={
              <PrivateRoute>
                <EditApartment />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/calendar/:id'}
            element={
              <PrivateRoute>
                <CalendarPage />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/my_account'}
            element={
              <PrivateRoute>
                <MyAccount />
              </PrivateRoute>
            }
          />
          <Route 
            path={'/edit_account/:id'}
            element={
              <PrivateRoute>
                <EditAccount />
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </AuthContextProvider>
  );
}

export default App;
