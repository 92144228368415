import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const ViewVr = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const user = auth.currentUser;
        const uid = user.uid;
        const userRef = doc(db, 'users', uid);
        getDoc(userRef).then(snapshot => {
            const data = snapshot.data();
            setUser(data)
        })
    }, [])
    return(
        
        <div className="vr_view">
            {user !== null ? (
                <>
                <iframe width="100%" height="100%" src={user?.apartment_url} />
                <div className="back_button">
                    <button onClick={() => navigate('/')}></button>
                </div>
                </>
            ) : (
                <div>
                    <CircularProgress />
                </div>
            )}
        </div>
    )
}
export default ViewVr;